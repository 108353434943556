:root {
  --aboutVh: 100vh;

}

body {
  padding-top: 73px;
}

.about-modal {
  background-color: #081f40;
  color: #fff;
  ;
}

nav {
  /* height: 50px; */
  box-shadow: rgba(233, 229, 229, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.2) 0px 6px 6px;
  background-color: white;

}



.img-h {
  height: 90vh;
}

.effect:hover {
  transform: scale(1.1);


}

.space {
  margin-top: 80px;
}

.carousel-indicators [data-bs-target] {

  background-color: #0e00a5;
}

.carousel-indicators .active {
  border-top: unset;
  border-bottom: unset;
  border: 1px solid transparent;
  border-left-width: 0;
  border-right-width: 0;
  border-radius: 10px;
  background-color: #081f40;
  height: 10px;
  width: 50px
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: #081f40;
}

.btn-primary {
  background-color: #081f40;

}

.caption {
  /* font-weight: 600;
    color: #081f40;
    height: 100px;
    left:280px;
    top: 180px;
    width: 300px;
    border-radius: 5px; */
  font-weight: 600;

  color: #081f40;

  height: 100px;
  left: 10px;
  top: 10px;
  text-align: left;

  width: 30%;
  padding: 10px;
  height: 40%;
  /* background-color: rgba(0, 0, 0, 0.125); */
  /* align-items: start; */
}

.nav-item a:hover {
  color: #fff;
}

.nav-item:hover {
  background-color: #081f40;


  box-shadow: rgba(0, 0, 0, 0.19) 0px 5px 10px, rgba(219, 219, 219, 0.2) 0px 3px 3px;
  border-radius: 8px;
}


/* #home{
  padding-bottom: 45px;
  
} */
.about-img {
  height: var(--aboutVh);
}

#about-us {
  height: var(--aboutVh);
  background-color: #081f40;
}

#products {
  height: 100vh;
}

#contact-us {
  height: 120vh;
  background-color: #081f40;
}

.logoImg {
  width: 130px;
}

/*cards*/
.one {
  background-color: #081f40;
  color: #FFFFFF;
  border-radius: 7px;
  transition: .5s;
}

.two {
  background-color: #28b8cf;
  color: #FFFFFF;
  border-radius: 7px;
  transition: .5s;

}

.three {
  background-color: #66e484;
  color: #FFFFFF;
  border-radius: 7px;
  transition: .5s;

}

.four {
  background-color: #92f43d;
  color: #FFFFFF;
  border-radius: 7px;
  transition: .5s;
}

.five {
  background-color: #4bdfb8;
  color: #FFFFFF;
  border-radius: 7px;
  transition: .5s;
}

.submtBtn {
  background-color: #92f43d;
  font-size: 16px;
  font-weight: 700;
  color: #FFFFFF;
  width: 100%;
}

.submtBtn:hover {
  background-color: #92f43d;
  font-size: 16px;
  font-weight: 700;
  color: #FFFFFF;
  width: 100%;
}

.servicesBtn {
  background-color: #081f40;
  font-weight: 600;
  color: #FFF;
}

.servicesBtn:hover {
  background-color: #081f40;
  font-weight: 600;
  color: #FFF;
}

.btnClassic {
  background-color: #FFF;
  font-weight: 700;
  color: #081f40;
}

.btnClassic:hover {
  background-color: #FFF;
  font-weight: 700;
  color: #081f40;
}


.wrapper {
  margin-top: 12px;
  display: flex;
  box-sizing: border-box;
  padding: 32px 16px;
}

.wrapper>div {
  box-sizing: border-box;
  width: calc(50% - 32px);
  float: left;
  margin: 0 16px;
}

.column2 {
  height: 100vh;
  color: #FFFFFF;
  position: relative;
  top: -32px;
  left: 25px;
}

.address-text {
  position: relative;
  top: 30px;
  left: 5px;
  font-weight: 400;
  font-size: 25px;
  float: left;
}

.m-hidden {
  color: transparent;
}

.add1 {
  font-weight: 400;
  font-size: 25px;
  float: left;
}

.mhlBtn {
  background-color: #92f43d;
  color: #FFFFFF;
  width: 250px;

  font-size: 25px;
}

.mhlBtn:hover {
  background-color: #92f43d;
  color: #FFFFFF;
  width: 250px;
  font-size: 25px;
}

.show-on-small-screen {
  display: none;
}

/*samsung and iphones moble bigger screens*/
@media (max-width: 979px) {
  body {
    padding-top: 73px;
  }

  .hide-on-small-screen {
    display: none;
  }

  .show-on-small-screen {
    display: block
  }


  .mhlBtn {
    width: 130px;
    color: #081f40;
    bottom: 45%;
    left: 34%;
    position: absolute;
    font-size: 13px;
    font-weight: 500;
  }

  .mhlBtn:hover {
    width: 130px;
    color: #081f40;
    bottom: 45%;
    left: 34%;
    position: absolute;
    font-size: 13px;
    font-weight: 500;
  }

.effect:hover{
  transform: translate(0);
}

  .carousel-indicators [data-bs-target] {

    background-color: #0e00a5;
    height: 5px;
    width: 30px;
    border-radius: 10px;
    border: 1px solid transparent;
  }

  .bread {
    display: none;
  }

  .carousel-indicators .active {

    border-top: unset;
    border-bottom: unset;
    border: 1px solid transparent;
    border-left-width: 0;
    border-right-width: 0;
    border-radius: 10px;
    background-color: #081f40;
    height: 5px;
    width: 30px
  }

  .mob {
    margin-left: -6%;
    margin-right: -6%;
  }

  .caption {
    padding: 5px;

    font-size: 10px;
    height: 100%;
    left: 0px;
    top: 0px;
    width: 100%;

    color: #fff;
    background-color: #081f4096;
  }

  .caption>p {
    font-size: 10px;
  }

  .caption>h2 {
    /* margin-top: -15px; */
    font-size: 20px;
  }

  .one {
    background-color: #081f40;
    color: #FFFFFF;
    border-radius: 7px;
  }

  .two {
    background-color: #28b8cf;
    color: #FFFFFF;
    border-radius: 7px;

  }

  .three {
    background-color: #66e484;
    color: #FFFFFF;
    border-radius: 7px;

  }

  .four {
    background-color: #92f43d;
    color: #FFFFFF;
    border-radius: 7px;
  }

  .five {
    background-color: #4bdfb8;
    color: #FFFFFF;
    border-radius: 7px;
    transition: .5s;
  }

  .space {
    margin-top: 80px;
  }

  #about-us {
    height: 430px;
    background-color: #081f40;
  }

  .card-size{
    margin-bottom: 10px;
    height: 260px !important;
    width: 3500px !important;
  }

  .card p{
    font-size: 16px !important;
  }

  .card h2{
    font-size: 20px !important;
  }

  #products {
    height: 1770px;
  }


  .card h2 {
    font-size: 20px;
  }

  #contact-us {
    height: 700px;
    background-color: #081f40;
  }

  .img-h {
    height: 90vh;
    width: 800px;

  }

  .m-hidden {
    display: none;
  }

  .slider-container {
    width: 90%;
    margin: auto;

  }

  .card {
    opacity: 1;
    transition: opacity 1s;
  }

  iframe {
    width: 250px !important;
    height: 350px;
  }

  .mobileView {
    display: none;
  }
 
}


iframe {
  width: 650px !important;
  height: 550px;
}

label {
  color: #FFFFFF;
}

.image-slider {
  position: relative;
  top: -24px;
  height: 100vh !important;
}

.slider-containerAb {
  opacity: 45% !important;
}


.list-class {
  list-style: none;
}

.readMorebtn {
  background: #92f43d;
  color: #081f40;
  font-size: 15px;
  font-weight: 500;
  float: right;
}

.readMorebtn:hover {
  background: #92f43d;
  color: #FFFFFF;
  font-size: 15px;
  font-weight: 500;
  float: right;
}

.footer-card {
  background: none;
}

h6 {
  font-weight: 900;
}

.lstclassC {
  list-style: none;
  float: left !important;
}